<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-card v-else>
      <v-card-title>
        Daftar Tournament
        <v-spacer></v-spacer>
        <v-row style="max-width: 600px">
          <v-col>
            <v-text-field
              v-model="search"
              dense
              label="Search"
              class="mb-2"
              hide-details
              small
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="field" :items="items" dense></v-select>
            <v-btn
              color="green"
              style="color: white; float: right"
              @click="doSearch"
              :loading="isLoading"
              small
            >
              Find
            </v-btn>
            <v-btn
              color="red"
              style="color: white; float: right"
              @click="resetSearch"
              :loading="isLoading"
              small
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        hide-default-footer
        :items="datas"
        :items-per-page="perPage"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.tournamentId }}</td>
            <td>{{ row.item.title }}</td>
            <td>{{ row.item.game_type.name }}</td>
            <td>{{ row.item.user.username }}</td>
            <td>{{ row.item.mode }}</td>
            <td>{{ row.item.pendingParticipant }}</td>
            <td>{{ row.item.acceptedParticipant }}</td>
            <td>{{ row.item.rejectedParticipant }}</td>
            <td>{{ row.item.view }}</td>
            <td>{{ format_date(row.item.created_at) }}</td>
            <td>
              <v-btn
                small
                depressed
                color="primary"
                @click="$router.push(`/tournament/${row.item.tournamentId}`)"
              >
                Detail
              </v-btn>
            </td>
          </tr>
        </template></v-data-table
      >
      <div class="float-right ma-3">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          circle
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen.vue";

export default {
  name: "Tournament",

  components: { LoadingScreen },

  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "tournamentId" },
        { text: "Judul Tournament", value: "title" },
        { text: "Tipe Game", value: "game_type.name" },
        { text: "Creator", value: "user.username" },
        { text: "Mode", value: "mode" },
        { text: "Pending Participant", value: "pendingParticipant" },
        { text: "Accepted Participant", value: "acceptedParticipant" },
        { text: "Rejected Participant", value: "rejectedParticipant" },
        { text: "Dilihat", value: "view" },
        { text: "Dibuat", value: "created_at" },
        { text: "Atur", value: "" },
      ],
      datas: [],
      field: "title",
      items: ["tournamentId", "title"],
      currentPage: 1,
      isLoading: true,
      perPage: 0,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    resetSearch() {
      this.search = "";
      this.currentPage = 1;
      this.fetch();
    },

    doSearch() {
      this.currentPage = 1;
      this.fetch();
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = this.search
        ? `${process.env.VUE_APP_API_URL}/v2/admin/tournaments?page=${this.currentPage}&key=${this.search}&field=${this.field}`
        : `${process.env.VUE_APP_API_URL}/v2/admin/tournaments?page=${this.currentPage}`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.isLoading = false;

          this.datas = response.data.result.data;
          this.totalPages = response.data.result.last_page;
          this.perPage = response.data.result.per_page;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.fetch();
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
